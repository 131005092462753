import React from 'react';
import './App.css';
import Timer from './Timer';
import Clock from "./Clock";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.loadState();
    }

    componentDidMount() {
        this.timerId = setInterval(() => this.tick(), 1000);
        this.saveTimerId = setInterval(() => this.saveState(this.state), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
        clearInterval(this.saveTimerId);
    }

    tick() {
        this.setState(state => {
            state.timers.forEach(function (timer) {
                if (timer.active) {
                    timer.time++;
                    state.activeTimer = timer;
                }
            });
            return state;
        });
    }

    toggle(index) {
        this.setState(state => {

            let timerActive = false;

            state.timers.forEach(function (timer, currentIndex) {
                if (index !== currentIndex) {
                    timer.active = false;
                }
            });

            state.timers[index].active = !state.timers[index].active;
            
            state.timers.forEach(timer => {
                if (timer.active) {
                    state.activeTimer = timer;
                    timerActive = true;
                }
            });

            if (!timerActive) {
                state.activeTimer = null;
            }

            this.saveState(state);
            return state;
        });
    }

    stop() {
        this.setState(state => {
            state.timers.forEach(function (timer) {
                timer.active = false;
            });
            this.saveState(state);
            return state;
        });
    }

    loadState = () => {
        try {
            const state = localStorage.getItem('timer_state');
            if (state) {
                return JSON.parse(state);
            }
        } catch (e) {
            console(e);
        }
        return {
            timers: [],
            activeTimer: null
        }
    };

    saveState = state => {
        localStorage.setItem('timer_state', JSON.stringify(state));
    };

    addTimer() {
        this.setState(state => {
            state.timers.push({
                title: 'Timer ' + (state.timers.length + 1),
                time: 0
            });
            this.saveState(state);
            return state;
        });
    }

    removeTimer(index) {
        this.setState(state => {
            state.timers.splice(index, 1);
            this.saveState(state);
            return state;
        });
    }

    renameTimer(event, index) {
        event.persist();
        this.setState(state => {
            state.timers[index].title = event.target.value;
            return state;
        });
    }

    renderList() {
        return this.state.timers.map((timer, index) =>
            <Timer key={index} index={index} timer={timer}
                   renameTimer={this.renameTimer.bind(this)}
                   remove={this.removeTimer.bind(this)}
                   toggle={this.toggle.bind(this)}
            />
        );
    }

    render() {
        return (
            <div className="App container">
                <h1>
                    {this.state.activeTimer ? this.state.activeTimer.title : 'ReactTimer'}
                </h1>
                <Clock activeTimer={this.state.activeTimer} />
                <div className="timers">
                    {this.renderList()}
                </div>
                <div className="mt-3">
                    <button className="pull-right btn btn-lg btn-primary" onClick={this.addTimer.bind(this)}>
                        <i className="fa fa-plus" />
                    </button>
                </div>
            </div>
        );
    }
}

export default App;
