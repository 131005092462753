import React from "react";
import formatTime from './timeFormatter';


class Clock extends React.Component
{
    render() {
        if (this.props.activeTimer) {
            return (
                <h2>
                    <code>{formatTime(this.props.activeTimer.time)}</code>
                </h2>
            );
        }
        return (
            <h2>
                <code>No timer active</code>
            </h2>
        )
    }
}

export default Clock;
