import React from 'react';
import formatTime from './timeFormatter';

class Timer extends React.Component
{
    render() {
        let timer = this.props.timer;
        return (
            <div className="Timer input-group mt-3">
                <input type="text" onChange={(event) => this.props.renameTimer(event, this.props.index)} className="form-control" value={timer.title} />
                <span className="timer-time-spent input-group-append">
                    <code className="input-group-text">
                        {formatTime(timer.time)}
                    </code>
                </span>
                <span className="input-group-append">
                    <button className="timer-start btn btn-outline-secondary" onClick={() => this.props.toggle(this.props.index)}>
                        <i className={timer.active ? 'fa fa-stop' : 'fa fa-play'} />
                    </button>
                </span>
                <span className="input-group-append">
                    <button className="timer-remove btn btn-outline-secondary" onClick={() => this.props.remove(this.props.index)}>
                        <i className="fa fa-minus" />
                    </button>
                </span>
            </div>
        );
    }
}

export default Timer;
